.modalOverlay.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(100%);
}

.modalOverlay.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.modalOverlay.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100%);
}
.sortModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
}

.sortModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: all 700ms ease-in-out;
}

.sortModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: all 700ms ease-in-out;
}
.filterModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(100%);
}
.filterModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}
.filterModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100%);
}
.cartModal.ReactModal__Overlay {
    opacity: 0;
    transition: all 700ms ease-in-out;
    transform: translateX(100%);
}

.cartModal.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.cartModal.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100%);
}
.cartSummaryModal.ReactModal__Overlay {
    transition: all 700ms ease-in-out;
}

.cartSummaryModal.ReactModal__Overlay--after-open {
    transition: all 700ms ease-in-out;
}
.cartSummaryModal.ReactModal__Overlay--after-close {
    transition: all 700ms ease-in-out;
}
@media screen and (max-width: 1024px) {
    .sortModal.ReactModal__Overlay {
        opacity: 0;
        transition: all 500ms ease-in-out;
        transform: translateY(100%);
    }

    .sortModal.ReactModal__Overlay--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    .sortModal.ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateY(100%);
    }
}
